import { useEffect, useState } from "react";
import Upload from "../../assets/images/upload.svg";
import { connect } from "react-redux";
import {
  setActiveKey,
  updatePersonalDoc,
  getPersonalDoc,
  beforeKyc,
} from "./kyc.action";

import {
  beforeUser,
  getUser,
  updateProfile,
} from "../../redux/user/user.action";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./KYC.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { allowedMediaTypes } = ENV;

function Additional_Files(props) {
  let storedData = ENV.getUserKeys();
  const [loader, setLoader] = useState(true);
  const [sentFile, setSentFile] = useState(false);
  const [data, setData] = useState({});
  const [image, setImage] = useState("");
  const [persData, setPersData] = useState({});
  const [msg, setMsg] = useState({
    Supportedfile:"",
  });
  useEffect(() => {
    if (storedData._id) props.getPersonalDoc(storedData._id);
  }, []);

  useEffect(() => {
    if (props.kyc.updateAuth) {
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.updateAuth]);

  useEffect(() => {
    if (props.kyc.personalDocAuth) {
      let personalData = props.kyc.personalDoc;
      if (personalData) {
        setPersData(personalData);
        if (personalData.additionalDocument)
          setImage(personalData.additionalDocument);
      }
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.personalDocAuth]);

  useEffect(() => {
    if (props.user.getUserAuth) {
      let user = props.user.getUserData;
      props.beforeUser();
      setLoader(false);
      if (
        user.firstName &&
        user.lastName &&
        user.dob &&
        persData.personalDocumentFront &&
        persData.personalDocumentBack &&
        persData.phone &&
        persData.country &&
        persData.addressDocument
      ) {
        props.setActiveKey("sixth");
      } else {
        toast.info("Please fill all required fields for completion.");
      }
    }
  }, [props.user.getUserAuth]);

  const onChange = (e) => {
    setMsg({ ...msg, Supportedfile: ""});
    if (e.target.files[0]) {
      const allowedImgTypes = allowedMediaTypes.images;
      let fileExt = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      if (allowedImgTypes.includes(fileExt)) {
        setImage(URL.createObjectURL(e.target.files[0]));
        setData({ ...data, additionalDocument: e.target.files[0]});
      }
      else {
        setMsg({
          Supportedfile: `Format not supported!! Supported file types are : ${allowedImgTypes}`,
        });
    } 
    }
  };
  const ClosedPic = () => {
    setImage('');
    setData({ ...data, additionalDocument: '' });
  };

  const save = () => {
   
    let formData = new FormData();
    for (const key in data) formData.append(key, data[key]);
    formData.append("userId", storedData._id);
    formData.append("appliedKYC", 2);
    formData.append("startApply", false);
    formData.append("rejectKYC", 1);
    props.updatePersonalDoc(formData);
    let payload = { _id: storedData._id, userKeyStatus: 3,isKycApplied:1 };
    props.updateProfile(payload);
    setLoader(true);
    setSentFile(true);
  };

  useEffect(() => {
    if (props.kyc.updateAuth && sentFile) {
      props.setbtnstatus(false)
      props.beforeKyc();
      next();
    }
  }, [props.kyc.updateAuth]);

  const next = () => {
    //props.getPersonalDoc(storedData._id);
    props.getUser(storedData._id);
    setLoader(true);
    props.setActiveKey("sixth");
  };

  
  return (
    <div className="additional-files">
      {loader ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="pt-4 d-flex justify-content-center align-items-center">
            <div className="file-content">
              <strong>Additional files</strong>
              <p>Please upload any additional file needed (optional)</p>
              {/* <div className="card d-flex justify-content-center align-items-center">
                <div className="mb-4 input-file">
                  <label>
                    {" "}
                    <img className="img-fluid" src={Upload} alt="" />
                    <input
                      type="file"
                      size="60"
                      accept=".png,.jpeg,.jpg"
                      onChange={onChange}
                    />
                  </label>
                </div>
                <div>
                  <span>Please upload a document (.JPEG, .JPG, or .PNG)</span>
                </div>
              </div> */}
              {msg.Supportedfile ? (
                  <span
                  className={msg.Supportedfile ? `` : `d-none`}
                >
                  <label className="pl-1 text-danger error">
                    {msg.Supportedfile}
                  </label>
                </span>
                ) : (
                  ""
                )}
              <>
                {image ? (
                  <div className="d-flex justify-content-center align-items-center card-img-uploader position-relative">
                    <div className="close-section"onClick={() => ClosedPic()}>
                        <FontAwesomeIcon className="text-white" icon={faXmark} />
                      </div>
                    <div className="text-center pt-3 pb-3 uploaded-files">
                      <img className="img-fluid" src={image} />
                    </div>
                  </div>
                ) : (
                  <div className="card d-flex justify-content-center align-items-center">
                  <div className="mb-4 input-file">
                    <label>
                      {" "}
                      <img className="img-fluid" src={Upload} alt="" />
                      <input
                        type="file"
                        size="60"
                        accept=".png,.jpeg,.jpg"
                        onChange={onChange}
                      />
                    </label>
                  </div>
                  <div>
                    <span>Please upload a document (.JPEG, .JPG, or .PNG)</span>
                  </div>
                </div>
                )}
                
              </>
              <div className="d-flex justify-content-center align-items-center pt-4 flex-wrap">
                <button
                  className="btn-triage-div btn-triage mb-3 mb-sm-0"
                  onClick={save}
                >
                  <span>Save</span>
                </button>
                {/* <button className="btn-triage-div btn-triage bg-white" onClick={next}><span>Next</span></button> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  kyc: state.kyc,
  user: state.user,
});

export default connect(mapStateToProps, {
  setActiveKey,
  updatePersonalDoc,
  getPersonalDoc,
  updateProfile,
  beforeKyc,
  beforeUser,
  getUser,
})(Additional_Files);
