import React, { useState, useEffect,useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";
import SubmitLoader from "../submitLoader/submitLoader";
import Select from "react-select";
import { connect } from "react-redux";
import {
  beforeAddress,
  addAddress,
  editAddress,
  getAddressList,
} from "../../redux/addressBook/addressBook.action";
import { networks } from "../../utils/networks";
import validator from "validator";
import { ENV } from "../../config/config";
import countryList from 'react-select-country-list'
function Crypto(props) {
  const { formType, dataObj, setSuccess, setSuccessMsg } = props;
  const [data, setData] = useState({
    type: "crypto",
    country: "",
    label: "",
    bic_swift: "",
    beneficiaryName: "",
    beneficiaryAddress: "",
    bankName: "",
    bankAddress: "",
    bankAccount: "",
  });
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedOption, setselectedOption] = useState({
    value: "",
    label: "Enter Country",
  });
  const countryOptions = useMemo(() => countryList().getData(), [])
  // const countryOptions = ENV.countriesOption;
  useEffect(() => {
    if (formType === "edit") {
      setselectedOption({ value: dataObj.country, label: dataObj.country });
      setData({ ...dataObj });
    }
  }, [formType]);

  useEffect(() => {
    if (props.address.addAddressAuth && formType === "add") {
      setLoader(false);
      initialData();
      setSuccess(true);
      setSuccessMsg(props.address.message);
      props.beforeAddress();
      props.getAddressList();
    }
  }, [props.address.addAddressAuth]);
  useEffect(() => {
    if (props.address.editAddressAuth) {
      setLoader(false);
      setSuccess(true);
      setSuccessMsg(props.address.message);
      props.beforeAddress();
      props.getAddressList();
    }
  }, [props.address.editAddressAuth]);

  const initialData = () => {
    setData({ type: "crypto" });
  };

  const changeData = (name, value) => {
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };
  
  const checkValidation = () => {
    let isValid = true;
    let err = {};
    if (validator.isEmpty(data.bankAccount) || (data.bankAccount).trim().length == 0) {
      console.log("kkkkkk")
      err["bankAccount"] = "Bank IBAN/Account number is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.bankAddress) || (data.bankAddress).trim().length == 0) {
      err["bankAddress"] = "Bank Address is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.bankName) || (data.bankName).trim().length == 0) {
      err["bankName"] = "Bank Name is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.beneficiaryAddress) || (data.beneficiaryAddress).trim().length == 0) {
      err["beneficiaryAddress"] = "Beneficiary Address is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.beneficiaryName) || (data.beneficiaryName).trim().length == 0) {
      err["beneficiaryName"] = "Beneficiary Name is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.bic_swift) || (data.bic_swift).trim().length == 0) {
      err["bic_swift"] = "BIC / SWIFT is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.label) || (data.label).trim().length == 0) {
      err["label"] = "Label is Required.";
      isValid = false;
    }
    if (validator.isEmpty(data.country) || (data.country).trim().length == 0){
      err["country"] = "Country Name is Required.";
      isValid = false;
    }

    setError({ ...err });
    return isValid;
  };

  const submitAddress = (e) => {
    e.preventDefault();
    if (checkValidation()) {
      setLoader(true);
      props.beforeAddress();
      if (formType === "add") {
        props.addAddress(data);
      } else if (formType === "edit") {
        props.editAddress(data);
      } else {
      }
    }
  };
  return (
    <div className="cypto-modal percent-records pt-5">
      <div>
        <Form onSubmit={(e) => submitAddress(e)}>
          <Row>
            <Col>
              <label>Label <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                  name="label"
                  placeholder="Enter Label"
                  value={data.label ? data.label : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["label"] && <p className="error">{error["label"]}</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Beneficiary Name<span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                  name="beneficiaryName"
                  placeholder="Enter Beneficiary Name"
                  value={data.beneficiaryName ? data.beneficiaryName : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["beneficiaryName"] && (
                  <p className="error">{error["beneficiaryName"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Beneficiary Address <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                placeholder="Enter Beneficiary Address"
                  name="beneficiaryAddress"
                  value={
                    data.beneficiaryAddress ? data.beneficiaryAddress : null
                  }
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["beneficiaryAddress"] && (
                  <p className="error">{error["beneficiaryAddress"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="input-field">
              <>
                <label for="country">
                  Country <span className="text-danger">*</span>
                </label>
                <Select
                // menuIsOpen="true"
                  className=" no-border input-field mb-0"
                  id="country"
                  name="country"
                  placeholder={"Enter Your Country"}
                  value={selectedOption}
                  defaultValue={data.country ? data.country : null}
                  onChange={(e) => {
                    setData({ ...data, country: e.label });
                    setError({ ...error, country: "" });
                    setselectedOption({ value: e.label, label: e.label });
                  }}
                  options={countryOptions}
                  classNamePrefix="select"
                />

                {error["country"] && (
                  <p className="error">{error["country"]}</p>
                )}
              </>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Beneficiary Bank Name <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                  name="bankName"
                  placeholder="Enter Beneficiary Bank Name"
                  value={data.bankName ? data.bankName : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["bankName"] && (
                  <p className="error">{error["bankName"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Beneficiary Bank Address <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                  name="bankAddress"
                  placeholder="Enter Beneficiary Bank Address"
                  value={data.bankAddress ? data.bankAddress : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["bankAddress"] && (
                  <p className="error">{error["bankAddress"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Beneficiary Bank IBAN/Account number <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                placeholder="Enter Beneficiary Bank IBAN/Account number"
                  name="bankAccount"
                  value={data.bankAccount ? data.bankAccount : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["bankAccount"] && (
                  <p className="error">{error["bankAccount"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>BIC/SWIFT <span className="text-danger">*</span></label>
              <div className="input-field">
                <input
                  name="bic_swift"
                  placeholder="Enter BIC/SWIFT"
                  value={data.bic_swift ? data.bic_swift : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["bic_swift"] && (
                  <p className="error">{error["bic_swift"]}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Comments</label>
              <div className="input-field">
                <input
                placeholder="Add Comment"
                  name="comment"
                  value={data.comment ? data.comment : null}
                  onChange={(e) => {
                    changeData(e.target.name, e.target.value);
                  }}
                />
                {error["comment"] && (
                  <p className="error">{error["comment"]}</p>
                )}
              </div>
            </Col>
          </Row>

          <div className="modal-footer justify-content-center">
            <div className="d-flex justify-content-center align-items-center">
              <button
                type="submit"
                className="btn-triage-div btn-triage d-flex justify-content-center align-items-center"
                onClick={(e) => {
                  submitAddress(e);
                }}
              >
                <span>Save</span>
                {loader && <SubmitLoader />}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  address: state.address,
});

export default connect(mapStateToProps, {
  beforeAddress,
  addAddress,
  editAddress,
  getAddressList,
})(Crypto);
