import React, { useState, useEffect } from 'react';
import SubmitLoader from '../submitLoader/submitLoader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link,useNavigate } from 'react-router-dom';
import { createStake, beforeStakeAuth } from '../../redux/dashboard/dashboard.action';
import { connect } from "react-redux";
import MessageAlert from "../messageAlert/messageAlert";
import { ENV } from "../../config/config"
import moment from 'moment';



const ConfirmationLockModal = (props) => {
    let userData = ENV.getUserKeys()
    const navigate = useNavigate()



    const { selectedProfitPlan, selectedToken, amount, selectedPlanCriteria, setStep, setAmount, setIsLockedConfirmed, isLockedConfirmed, bonus, promoCodeUsed, setShowConfirmationModal, setSelectedToken, setShowMessageAlert,walletsCall,tokenBal,gasFee } = props

    let plan = props.selectedProfitPlan

    let startDate= moment()
    let endDate = moment().add(selectedProfitPlan?.months, 'M');
    let diffInDays = endDate.diff(startDate, 'days');
    let promoProfit = (parseInt(bonus) + parseInt(selectedProfitPlan?.profit))
    let profitAtDueDay = bonus ?   props.amount * promoProfit / 100    : props.amount * selectedProfitPlan?.profit / 100
    let dailyProfit = profitAtDueDay / diffInDays

    const [loader, setloader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)


    useEffect(() => {
        if (props.dashboard.createStakeAuth) {
            setSubmitLoader(false)
            setloader(false)
            setSuccess(true)
            setShowMessageAlert(true)
            setIsLockedConfirmed(true)
            setShowConfirmationModal(false)
            // setSelectedToken(null)
            props.beforeStakeAuth()
            walletsCall()
            navigate(`/history?activity=locked`)
        }
    }, [props.dashboard.createStakeAuth])

    const callStakeApi = async () => {
        // create the stake function
        setloader(true)
        setSubmitLoader(true)

        let data = {
            userId: userData._id,
            criteriaId: selectedPlanCriteria?._id,
            months: selectedPlanCriteria?.months,
            currency: selectedToken.symbol,
            depositedAmount: amount,
            gasFee,
            totalProfit: profitAtDueDay,
            profitType: selectedProfitPlan.type === "Available" ? 1 : selectedProfitPlan.type === "Locked" ? 2 : 0,
            dailyProfit
        }

        if(amount > tokenBal) {
            let profitBalanceUsed = amount - tokenBal 
            data.profitBalanceUsed = profitBalanceUsed
        }

        if (promoCodeUsed) {
            data["promoCode"] = promoCodeUsed
        }
        props.createStake(data)

    }

    return (
        <>
            {
                selectedToken &&
                <Modal
                    className='review-modal triage-model'
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Confirmation Lock
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                <div className='mb-2 mb-sm-0'><span>Lock Term in Months</span></div>
                                <div><span>{selectedProfitPlan.months}</span></div>
                            </li>
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                <div className='mb-2 mb-sm-0'><span>Locked Amount </span></div>
                                <div><span>   {props?.amount} {props?.selectedToken?.symbol}</span></div>
                            </li>
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                <div className='mb-2 mb-sm-0'><span>Total Profit Amount </span></div>
                                <div><span> {profitAtDueDay} {props?.selectedToken?.symbol}</span></div>
                            </li>
                            {selectedProfitPlan?.type === "Available" &&
                                <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                    <div className='mb-2 mb-sm-0'><span>Daily Interest  </span></div>
                                    <div><span>{dailyProfit} TRI</span></div>
                                </li>}
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                <div className='mb-2 mb-sm-0'><span>Plan End Date  </span></div>
                                <div><span>{moment().add(selectedProfitPlan?.months, 'M').format('DD-MM-YYYY')}</span></div>
                            </li>

                        </ul>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center' >
                        <div className="d-flex justify-content-center flex-wrap">
                            {
                                success ? <p className='sucess-text'>Confirmed Successfully.</p> :
                                    <button className="btn-triage-div btn-triage me-4 mb-3 mb-md-0 me-3 d-flex justify-content-center align-items-center" onClick={() => { callStakeApi() }} >
                                        <span>CONFIRM</span>
                                        {submitLoader && <SubmitLoader />}
                                        {/* {
                                                loader && <SubmitLoader />
                                            } */}
                                    </button>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            }

        </>
    );
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    user: state.user
})

export default connect(mapStateToProps, { createStake, beforeStakeAuth })(ConfirmationLockModal)
