import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ReviewModal = (props) => {

    const [isCheckedTerms, setIsCheckedTerms] = useState(false)
    const [error, setError] = useState({})

    let plan = props.selectedProfitPlan
    let bonus = props.bonus
    let promoProfit = (parseInt(bonus) + parseInt(plan?.profit))
    let startDate = moment()
    let endDate = moment().add(plan.months, 'M');
    let diffInDays = endDate.diff(startDate, 'days');
    let profitAtDueDay = bonus ?   props.amount * promoProfit / 100    : props.amount * plan?.profit / 100
    let dailyProfit = profitAtDueDay / diffInDays

    const validation = () => {
        let isValid = true
        let err = []
        if (!isCheckedTerms) {
            isValid = false
            err["isChecked"] = "Please Check the Terms & Condition."
        }
        setError({ ...err })
        return isValid
    }

    const lockPlan = () => {
        if (validation()) {
            props.setShowConfirmationModal(true);
            props.onHide();
        }
    }

    return (
        <Modal
            className='review-modal triage-model'
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className='text-white p-0'>Lock Amount</h2>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li className='d-flex justify-content-between mb-4 flex-wrap'>
                        <div className='mb-2 mb-sm-0'><span>Lock Term in Months </span></div>
                        <div><span> {plan.months}</span></div>
                    </li>
                    <li className='d-flex justify-content-between mb-4 flex-wrap'>
                        <div className='mb-2 mb-sm-0'><span>Locked Amount </span></div>
                        <div><span>   {props?.amount} {props?.selectedToken?.symbol}</span></div>
                    </li>
                    {bonus &&
                        <>
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                {/* <div className='mb-2 mb-sm-0'><span>Plan Profit Amount  </span></div> */}
                                <div className='mb-2 mb-sm-0'><span>Plan profit percentage </span></div>
                                <div><span>{plan?.profit}%</span></div>
                            </li>
                            <li className='d-flex justify-content-between mb-4 flex-wrap'>
                                <div className='mb-2 mb-sm-0'><span>Promo Code Bonus  </span></div>
                                <div><span>{bonus}%</span></div>
                            </li>
                        </>
                    }
                    <li className='d-flex justify-content-between mb-4 flex-wrap'>
                        <div className='mb-2 mb-sm-0'><span>Total Profit Amount </span></div>
                        <div><span> {profitAtDueDay} {props?.selectedToken?.symbol}</span></div>
                    </li>
                    {plan.type === "Available" &&
                        <li className='d-flex justify-content-between mb-4 flex-wrap'>
                            <div className='mb-2 mb-sm-0'><span>Daily Interest  </span></div>
                            <div><span>{dailyProfit} TRI</span></div>
                        </li>}
                    <li className='d-flex justify-content-between mb-4 flex-wrap'>
                        <div className='mb-2 mb-sm-0'><span>Plan End Date  </span></div>
                        <div><span>{moment().add(plan.months, 'M').format('DD-MM-YYYY')}</span></div>
                    </li>
                </ul>
                <div className="form-group d-flex m-2">
                    <div>
                        <input type="checkbox" id="html" onChange={() => { setIsCheckedTerms(prevValue => !prevValue) }} />

                        <label for="html">
                            <span >I agree with <Link to="/terms-conditions" target="_blank" rel="noopener noreferrer" className=" ms-1">Term &amp; Conditions.</Link></span>
                        </label>
                    </div>


                </div>
                {
                    error["isChecked"] && <p className='error'>{error["isChecked"]}</p>
                }
            </Modal.Body>
            <Modal.Footer className='justify-content-center' >
                <div className="d-flex justify-content-center flex-wrap">
                    <button className="btn-triage-div btn-triage me-4 mb-3 mb-md-0 me-3 pervious-btn" onClick={() => { setIsCheckedTerms(false); setError({}); props.onHide() }} ><span>PERVIOUS</span></button>
                    <button className="btn-triage-div btn-triage me-4 mb-3 mb-md-0 me-3 " onClick={() => { lockPlan() }} ><span>LOCK</span></button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ReviewModal

