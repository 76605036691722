import { useEffect, useState } from "react";
import codes from "country-calling-code";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import Upload from "../../assets/images/upload.svg";
import {
  setActiveKey,
  updatePersonalDoc,
  getPersonalDoc,
  beforeKyc,
} from "./kyc.action";
import Select from "react-select";
import { ENV } from "../../config/config";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./KYC.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const { allowedMediaTypes } = ENV;

function Address(props) {
  let storedData = ENV.getUserKeys();
  const [country, setCountry] = useState([]);
  const [data, setData] = useState({
    addressDocument: "",
    country: "",
  });
  const [msg, setMsg] = useState({
    country: "",
    addressDocument: "",
  });
  const [loader, setLoader] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [image, setImage] = useState("");

  useEffect(() => {
    if (storedData._id) props.getPersonalDoc(storedData._id);
    setCountry(
      codes.map((item) => {
        return {
          value: item.country,
          label: item.country,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (props.kyc.updateAuth) {
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.updateAuth]);

  useEffect(() => {
    if (props.kyc.personalDocAuth) {
      let personalData = props.kyc.personalDoc;
      if (personalData) {
        setData({
          addressDocument: personalData.addressDocument || null,
          country: personalData.country,
        });
        codes.map((item) => {
          if (`${item.country}` == personalData.country) {
            // console.log(
            //   "{value: item.countryCodes[0], label: item.country + ' (+' + item.countryCodes[0] + ')'}",
            //   {
            //     value: item.countryCodes[0],
            //     label: item.country
            //   }
            // );
            setSelectedOption({
              value: item.countryCodes[0],
              label: item.country,
            });
            //setCountry([{ value: item.country, label: item.country }]);
          }
        });
        if (personalData.addressDocument)
          setImage(personalData.addressDocument);
      }
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.personalDocAuth]);

  const onSelectChange = (e) => {
    setMsg({ ...msg, country: "" });

    setData({
      ...data,
      country: e.value,
    });
    setSelectedOption({ value: e.value, label: e.value });
  };

  const onChange = (e) => {
    setMsg({ ...msg, addressDocument: "", Supportedfile: "" });
    if (e.target.files[0]) {
      const allowedImgTypes = allowedMediaTypes.images;
      let fileExt = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      if (allowedImgTypes.includes(fileExt)) {
        setImage(URL.createObjectURL(e.target.files[0]));
        setData({ ...data, addressDocument: e.target.files[0] });
      }
      else {
        setMsg({
          Supportedfile: `Format not supported!! Supported file types are : ${allowedImgTypes}`,
        });
      }
    }
  };
  const ClosedPic = () => {
    setImage('')
    setData({ ...data, addressDocument: '' });
  };
  const save = () => {
    if (data.country && data.addressDocument) {
      let formData = new FormData();
      for (const key in data) formData.append(key, data[key]);
      formData.append("userId", storedData._id);
      props.updatePersonalDoc(formData);
      setLoader(true);
      setMsg({
        country: "",
        addressDocument: "",
      });
      props.setActiveKey("fifth");
    } else {
      let country = "";
      let addressDocument = "";
      if (!data.country) {
        country = "Country Is Required.";
      }
      if (!data.addressDocument) {
        addressDocument = "Document With Address Is Required.";
      }
      setMsg({
        country,
        addressDocument,
      });
    }
  };

  return (
    <div className="address">
      {loader ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div className="account-inputs mb-0">
                <div className="account-inputs mb-0">
                  <label>
                    Your Country<span className="text-danger"> *</span>
                  </label>
                  <div className="account-inputs phone-number-select">
                    <div style={{ minHeight: "0" }} className="p-0 country-name-selector mb-0">
                      <Select
                        className="w-100"
                        value={selectedOption}
                        onChange={onSelectChange}
                        options={country}
                        classNamePrefix="triage-select country-code-select"
                      />
                    </div>
                  </div>
                </div>
                {/* <div> */}
                <span className={msg.country ? `` : `d-none`}>
                  <span className="pl-1 text-danger error">
                    {msg.country}
                  </span>
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="pt-4 d-flex justify-content-center align-items-center">
            <div className="upload-div account-inputs">
              <strong>
                Add document with address on it
                <span className="text-danger"> *</span>
              </strong>
              <p>Please upload document proving your current address</p>
              {/* <div>
                <span className={msg.addressDocument ? `` : `d-none`}>
                  <label className="pl-1 text-danger error">
                    {msg.addressDocument}
                  </label>
                </span>
              </div> */}

              {image ? (
                <div className="d-flex justify-content-center align-items-center card-img-uploader">
                  <div className="text-center pt-3 pb-3 uploaded-files position-relative">
                    <div className="close-section" onClick={() => ClosedPic()}>
                      <FontAwesomeIcon className="text-white" icon={faXmark} />
                    </div>
                    <img className="img-fluid" src={image} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card d-flex justify-content-center align-items-center">
                    <div className="mb-4 input-file">
                      <label className="text-center">
                        {" "}
                        <img className="img-fluid" src={Upload} alt="" />
                        <input
                          type="file"
                          size="60"
                          accept=".png,.jpeg,.jpg"
                          onChange={onChange}
                          name="addressDocument"
                        />
                      </label>
                    </div>
                    <div>
                      <span>Please upload a document (.JPEG, .JPG, or .PNG)</span>
                    </div>

                  </div>
                  <div>
                    <span className={msg.addressDocument ? `` : `d-none`}>
                      <span className="pl-1 text-danger error">
                        {msg.addressDocument}
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {msg.Supportedfile ? (
                <span className={msg.Supportedfile ? `` : `d-none`}>
                  <span className="pl-1 text-danger error">
                    {msg.Supportedfile}
                  </span>
                </span>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-center align-items-center pt-4 flex-wrap">
                <button
                  className="btn-triage-div btn-triage mb-3 mb-sm-0"
                  onClick={save}
                >
                  <span>Save</span>
                </button>
                {/* <button className="btn-triage-div btn-triage bg-white mb-3 mb-sm-0" onClick={() => { props.setActiveKey('fifth') }}><span>Next</span></button> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  kyc: state.kyc,
});

export default connect(mapStateToProps, {
  setActiveKey,
  updatePersonalDoc,
  getPersonalDoc,
  beforeKyc,
})(Address);
