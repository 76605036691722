import React from "react";
import "./dashboard.css"
import { Table } from "react-bootstrap";

function Availabe_Profit({ stats,sum }) {
    console.log(stats, "stats")
    return (
        <div className="available-profit">
            <div className="history p-3 ">
                {stats?.length > 0 && stats[0].availableProfit > 0 ? <div className="table-responsive">
                    <Table className="mb-0" striped bordered hover>
                        <thead>
                            <tr>
                                <th>Deposited Amount</th>
                                <th>Daily Profit (TRI)</th>
                            </tr>
                        </thead>
                        <tbody>
                             <tr>
                                    <td>{sum}</td>
                                    <td>{stats && stats[0].availableProfit.toFixed(4)}</td>
                                </tr>
                        </tbody>
                    </Table>

                </div> :
                    <div className="main-content ">
                        <strong className="">No Record Found</strong>
                    </div>}
            </div>
        </div>
    )
}
export default Availabe_Profit
