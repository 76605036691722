import { useState, useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Upload from "../../assets/images/upload.svg";
import Passport from "./passport";
import Driving_ID from "./drivingId";
import ID_Card from "./idCard";
import { connect } from "react-redux";
import {
  setActiveKey,
  unSetKycData,
  updatePersonalDoc,
  getPersonalDoc,
  beforeKyc,
} from "./kyc.action";
import { ENV } from "../../config/config";
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import "./KYC.css";

const { allowedMediaTypes } = ENV;

function Personal_Document(props) {
  let storedData = ENV.getUserKeys();
  const [data, setData] = useState({
    personalDocumentPassportFront: "",
    personalDocumentPassportBack: "",
    personalDocumentIDCardFront: "",
    personalDocumentIDCardBack: "",
    personalDocumentDrivingIDFront: "",
    personalDocumentDrivingIDBack: "",
    personalDocumentType: "1",
  });
  const [saveData, setSaveData] = useState({
    personalDocumentType: "1",
    userId: storedData._id,
  });
  const [msg, setMsg] = useState({
    personalDocumentPassportFront: "",
    personalDocumentPassportBack: "",
    personalDocumentIDCardFront: "",
    personalDocumentIDCardBack: "",
    personalDocumentDrivingIDFront: "",
    personalDocumentDrivingIDBack: "",
    Supportedfilefront: "",
    Supportedfileback: "",
  });

  const [imageChangeCheck, setImageChangeCheck] = useState(false);
  const [loader, setLoader] = useState(true);
  const [controller, setController] = useState([false, false, false]);

  useEffect(() => {
    if (imageChangeCheck) {
      setImageChangeCheck(false);
    }
  }, [imageChangeCheck]);

  useEffect(() => {
    if (saveData.userId) props.getPersonalDoc(saveData.userId);
  }, []);

  useEffect(() => {
    if (props.kyc.personalDocAuth) {
      let personalData = props.kyc.personalDoc;
      if (personalData) {
        if (
          personalData.personalDocumentPassportFront ||
          personalData.personalDocumentPassportBack ||
          personalData.personalDocumentIDCardFront ||
          personalData.personalDocumentIDCardBack ||
          personalData.personalDocumentDrivingIDFront ||
          personalData.personalDocumentDrivingIDBack
        ) {
          let localArr = new Array(3).fill(true);
          localArr[personalData.personalDocumentType - 1] = false;
          setController(localArr);
        }
        if (personalData.personalDocumentType)
        {        setData(personalData);
          setSaveData({
          ...saveData,
          personalDocumentType: personalData.personalDocumentType,
        });}
          
      }
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.personalDocAuth]);

  useEffect(() => {
    if (props.kyc.updateAuth) {
      setLoader(false);
      props.beforeKyc();
    }
  }, [props.kyc.updateAuth]);

  const ClosedPic = (name) => {
    console.log(name);
    let objData = data;
    objData[name] = "";
    console.log("isthis")
    setData({ ...objData });
  };


  const onChange = (name, imageURL, imagefile) => {
    setMsg({
      personalDocumentPassportFront: "",
      personalDocumentPassportBack: "",
      personalDocumentIDCardFront: "",
      personalDocumentIDCardBack: "",
      personalDocumentDrivingIDFront: "",
      personalDocumentDrivingIDBack: "",
      Supportedfilefront: "",
      Supportedfileback: "",
    });
    if (imageURL) {
      if (name && imageURL) {
        let newData = data;
        newData[name] = imageURL;
        console.log("is that")
        setData(newData);
        let newSaveData = saveData;
        newSaveData[name] = imagefile;
        setSaveData(newSaveData);
        setImageChangeCheck(true);
      } else {
        if (
          name === "personalDocumentPassportFront" ||
          name === "personalDocumentDrivingIDFront" ||
          name === "personalDocumentIDCardFront"
        ) {
          setMsg({
            Supportedfilefront: `Format not supported!! Supported file types are : ${allowedMediaTypes}`,
          });
        } else if (
          name === "personalDocumentPassportBack" ||
          name === "personalDocumentIDCardBack" ||
          name === "personalDocumentDrivingIDBack"
        ) {
          setMsg({
            Supportedfileback: `Format not supported!! Supported file types are : ${allowedMediaTypes}`,
          });
        }
      }
    }
  };

  const saveClick = () => {
    if (data.personalDocumentType == 1) {
      if (
        data.personalDocumentPassportFront &&
        data.personalDocumentPassportBack
      ) {
        setMsg({
          personalDocumentPassportFront: "",
          personalDocumentPassportBack: "",
          personalDocumentIDCardFront: "",
          personalDocumentIDCardBack: "",
          personalDocumentDrivingIDFront: "",
          personalDocumentDrivingIDBack: "",
        });
        let localArr = new Array(3).fill(true);
        localArr[parseInt(data.personalDocumentType) - 1] = false;
        setController(localArr);
        let formData = new FormData();
        for (const key in saveData) formData.append(key, saveData[key]);
        props.updatePersonalDoc(formData);
        setLoader(true);
        props.setActiveKey("forth");
      } else if (
        !data.personalDocumentPassportFront &&
        !data.personalDocumentPassportBack
      ) {
        setMsg({
          personalDocumentPassportFront: "Personal Document is Required",
          personalDocumentPassportBack: "Personal Document is Required",
        });
      } else if (!data.personalDocumentPassportFront) {
        setMsg({
          ...msg,
          personalDocumentPassportFront: "Personal Document is Required",
        });
      } else if (!data.personalDocumentPassportBack) {
        setMsg({
          ...msg,
          personalDocumentPassportBack: "Personal Document is Required",
        });
      }
    }
    if (data.personalDocumentType == 2) {
      if (data.personalDocumentIDCardFront && data.personalDocumentIDCardBack) {
        setMsg({
          personalDocumentPassportFront: "",
          personalDocumentPassportBack: "",
          personalDocumentIDCardFront: "",
          personalDocumentIDCardBack: "",
          personalDocumentDrivingIDFront: "",
          personalDocumentDrivingIDBack: "",
        });
        let localArr = new Array(3).fill(true);
        localArr[parseInt(data.personalDocumentType) - 1] = false;
        setController(localArr);
        let formData = new FormData();
        for (const key in saveData) formData.append(key, saveData[key]);
        props.updatePersonalDoc(formData);
        setLoader(true);
        props.setActiveKey("forth");
      } else if (
        !data.personalDocumentIDCardFront &&
        !data.personalDocumentIDCardBack
      ) {
        setMsg({
          personalDocumentIDCardFront: "Personal Document is Required",
          personalDocumentIDCardBack: "Personal Document is Required",
        });
      } else if (!data.personalDocumentIDCardFront) {
        setMsg({
          ...msg,
          personalDocumentIDCardFront: "Personal Document is Required",
        });
      } else if (!data.personalDocumentIDCardBack) {
        setMsg({
          ...msg,
          personalDocumentIDCardBack: "Personal Document is Required",
        });
      }
    }
    if (data.personalDocumentType == 3) {
      if (
        data.personalDocumentDrivingIDFront &&
        data.personalDocumentDrivingIDBack
      ) {
        setMsg({
          personalDocumentPassportFront: "",
          personalDocumentPassportBack: "",
          personalDocumentIDCardFront: "",
          personalDocumentIDCardBack: "",
          personalDocumentDrivingIDFront: "",
          personalDocumentDrivingIDBack: "",
        });
        let localArr = new Array(3).fill(true);
        localArr[parseInt(data.personalDocumentType) - 1] = false;
        setController(localArr);
        let formData = new FormData();
        for (const key in saveData) formData.append(key, saveData[key]);
        props.updatePersonalDoc(formData);
        setLoader(true);
        props.setActiveKey("forth");
      } else if (
        !data.personalDocumentDrivingIDFront &&
        !data.personalDocumentDrivingIDBack
      ) {
        setMsg({
          personalDocumentDrivingIDFront: "Personal Document is Required",
          personalDocumentDrivingIDBack: "Personal Document is Required",
        });
      } else if (!data.personalDocumentDrivingIDFront) {
        setMsg({
          ...msg,
          personalDocumentDrivingIDFront: "Personal Document is Required",
        });
      } else if (!data.personalDocumentDrivingIDBack) {
        setMsg({
          ...msg,
          personalDocumentDrivingIDBack: "Personal Document is Required",
        });
      }
    }
  };
  return (
    <div className="personal-document">
      {loader ? (
        <FullPageLoader />
      ) : (
        <>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={
              data.personalDocumentType ? data.personalDocumentType : "1"
            }
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="tabs-sec">
                <Nav variant="pills">
                  <Nav.Item className="me-0 me-sm-4">
                    <Nav.Link
                      className="text-white"
                      eventKey="1"
                      href="#"
                      onClick={() => {
                        setSaveData({ ...saveData, personalDocumentType: "1" });
                        setData({ ...data, personalDocumentType: "1" });
                      }}
                      disabled={
                        controller && controller.length ? controller[0] : false
                      }
                    >
                      Passport
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-4 ms-0 ms-sm-4">
                    <Nav.Link
                      className="text-white"
                      eventKey="2"
                      href="#"
                      onClick={() => {
                        setSaveData({ ...saveData, personalDocumentType: "2" });
                        setData({ ...data, personalDocumentType: "2" });
                      }}
                      disabled={
                        controller && controller.length ? controller[1] : false
                      }
                    >
                      ID Card
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="ms-0 ms-sm-4">
                    <Nav.Link
                      className="text-white"
                      eventKey="3"
                      href="#"
                      onClick={() => {
                        setSaveData({ ...saveData, personalDocumentType: "3" });
                        setData({ ...data, personalDocumentType: "3" });
                      }}
                      disabled={
                        controller && controller.length ? controller[2] : false
                      }
                    >
                      Driving ID
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            {
              console.log("data.personalDocumentType",data.personalDocumentType)
            }
            <Tab.Content>
              {data.personalDocumentType == "1" ? (
                <Passport
                  onChange={onChange}
                  data={data}
                  ClosedPic={ClosedPic}
                  msg={msg}
                />
              ) : data.personalDocumentType == "2" ? (
                <ID_Card
                  onChange={onChange}
                  data={data}
                  ClosedPic={ClosedPic}
                  msg={msg}
                />
              ) : data.personalDocumentType == "3" ? (
                <Driving_ID
                  onChange={onChange}
                  data={data}
                  ClosedPic={ClosedPic}
                  msg={msg}
                />
              ) : (
                ""
              )}
            </Tab.Content>
          </Tab.Container>
          <div className="passport">
            <div className="d-flex justify-content-center align-items-center pt-4">
              <button className="btn-triage-div btn-triage" onClick={saveClick}>
                <span>Save</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  kyc: state.kyc,
});

export default connect(mapStateToProps, {
  setActiveKey,
  unSetKycData,
  updatePersonalDoc,
  getPersonalDoc,
  beforeKyc,
})(Personal_Document);
