// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// USER
export const BEFORE_USER = 'BEFORE_USER';
export const SET_USER = 'SET_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const BEFORE_USER_VERIFY = 'BEFORE_USER_VERIFY';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const GET_USER = 'GET_USER';
export const GET_USER_REFERRALS = 'GET_USER_REFERRALS';
export const GET_USER_HISTORY = 'GET_USER_HISTORY'; 
export const GET_CRITERIA_LIST = 'GET_CRITERIA_LIST';
export const SET_USER_BALANCE = 'SET_USER_BALANCE';

// WALLET SETUP should be removed when not needed
export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";
export const REDIRECT_TO_WALLET = 'REDIRECT_TO_WALLET';
export const DISCONNECT_USER = "DISCONNECT_USER";

// SECURITY
export const ENABLE_SECURITY = "ENABLE_SECURITY";
export const GET_SECURITY = "GET_SECURITY";
export const BEFORE_SECURITY = "BEFORE_SECURITY";

// REQUEST
export const SEND_REQUEST = "SEND_REQUEST";
export const BEFORE_REQUEST = "BEFORE_REQUEST";

// ADDRESS BOOK 
export const BEFORE_ADDRESS = "BEFORE_ADDRESS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_ADDRESS = "GET_ADDRESS";

// KYC
export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY';
export const SET_KYC_DATA = 'SET_KYC_DATA';
export const UNSET_KYC_DATA = 'UNSET_KYC_DATA';
export const UPDATE_KYC_DOC = 'UPDATE_KYC_DOC';
export const BEFORE_KYC = 'BEFORE_KYC';
export const GET_Personal_Doc = 'GET_Personal_Doc';

// CMS
export const GET_CMS = 'GET_CMS';
export const BEFORE_CMS = 'BEFORE_CMS';
export const LIST_CMS = 'LIST_CMS';

// Wallet 
export const BEFORE_WALLET = "BEFORE_WALLET";
export const GET_WALLETS = "GET_WALLETS";
export const GET_WALLET = "GET_WALLET";
export const GET_SYMBOLS = "GET_SYMBOLS";

// Payment
export const PAYMENT_STATUS = "PAYMENT_STATUS";

// FOOTER
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_FOOTER = 'GET_FOOTER';
export const SUBMIT_FOOTER = 'SUBMIT_FOOTER'; 
export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const GET_CURRENCIES_DATA = 'GET_CURRENCIES_DATA';

// FAQ_CAT
export const BEFORE_FAQ_CAT = 'BEFORE_FAQ_CAT';
export const LIST_FAQ_CAT = 'LIST_FAQ_CAT';

// FAQ
export const BEFORE_FAQ = 'BEFORE_FAQ';
export const LIST_FAQ = 'LIST_FAQ';

// PROMOS 
export const BEFORE_PROMO_CODE = 'BEFORE_PROMO_CODE';
export const VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE';
export const USED_PROMOS = 'USED_PROMOS';

// ACCOUNT_LEVELS
export const LIST_ACCOUNT_LEVELS = 'LIST_ACCOUNT_LEVELS';
export const BEFORE_ACCOUNT_LEVELS = 'BEFORE_ACCOUNT_LEVELS';
export const SEARCH_LEVEL_DETAILS = 'SEARCH_LEVEL_DETAILS';
export const SEARCH_LEVEL_ANNUALIZED = 'SEARCH_LEVEL_ANNUALIZED';
export const MIN_INVESTMENT = 'MIN_INVESTMENT';

// Currency values or caps at dashboard
export const BEFORE_CURRENCIES_CAPS = 'BEFORE_CURRENCIES_CAPS';
export const GET_CURRENCIES_CAPS = 'GET_CURRENCIES_CAPS';
export const BEFORE_SAVING_PLANS = 'BEFORE_SAVING_PLANS';
export const GET_SAVING_PLANS = 'GET_SAVING_PLANS';
export const LIST_CURRENCY_STATS = 'LIST_CURRENCY_STATS';
export const BEFORE_CURRENCT_STATS = 'BEFORE_CURRENCT_STATS';

export const BEFORE_PLANS_PROFIT = 'BEFORE_PLANS_PROFIT';
export const GET_PLANS_PROFIT = 'GET_PLANS_PROFIT';

export const CREATE_STAKE_AUTH = 'CREATE_STAKE_AUTH';
export const GET_STAKE_AMOUNT = 'GET_STAKE_AMOUNT';
export const BEFORE_STAKE_AMOUNT = 'BEFORE_STAKE_AMOUNT';
export const BEFORE_STAKE_AUTH = 'BEFORE_STAKE_AUTH';

// HISTORY
export const BEFORE_HISTORY = 'BEFORE_HISTORY';
export const LIST_HISTORY = 'LIST_HISTORY';
export const CREATE_HISTORY = 'CREATE_HISTORY';


// 2FA
export const BEFORE_QR = 'BEFORE_QR';
export const GET_QR_CODE = 'GET_QR_CODE';
export const OTP_VERIFIED = 'OTP_VERIFIED';

// SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS'; 
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS'; 
export const CREATE_WIRE_REQUEST = 'CREATE_WIRE_REQUEST' 
export const DELETE_WIRE_REQUEST = 'DELETE_WIRE_REQUEST' 
export const BEFORE_DELETE_WIRE_REQUEST = 'BEFORE_DELETE_WIRE_REQUEST' 
export const UPLOAD_RECEIPT = 'UPLOAD_RECEIPT' 
export const ADD_WITHDRAW_REQUEST = 'ADD_WITHDRAW_REQUEST'  
export const UPDATE_WITHDRAW_STATUS = 'UPDATE_WITHDRAW_STATUS' 
export const GET_DYNAMIC_PAGES_DATA = 'GET_DYNAMIC_PAGES_DATA'
export const GET_USER_PROFIT = 'GET_USER_PROFIT'



