import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useParams,useNavigate } from "react-router-dom";
import Rating from "../../assets/images/rate.png"
import Regulate from "../../assets/images/regulated.png"
import Fifty from "../../assets/images/50.svg"
import Shape from "../../assets/images/triage-shape.svg"
import { ENV } from '../../config/config'
import "./login.css"
import validator from "validator";
import { connect } from 'react-redux'
import { resetPassword, beforeUser } from '../../redux/user/user.action'
import PasswordInput from "../passwordInput/passwordInput";
import SubmitLoader from "../submitLoader/submitLoader";


function ResetPassword(props) {
    const navigate = useNavigate()
    let { userId, resetPasswordToken } = useParams();
    let storedData = ENV.getUserKeys()

    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    })

    useEffect(()=> {
        if(storedData._id) {
            navigate("/wallet")
        }
    },[])

    const [err, setErr] = useState({})
    const [resetErr, setResetErr] = useState({})
    const [showPwd, setShowPwd] = useState(false)
    const [showPwdConfirm, setShowPwdConfirm] = useState(false)
    const [loader, setLoader] = useState(false)


    const changeData = (name, value) => {
        let tempData = data
        tempData[name] = value
        if(!value) {
            setErr({})
            setResetErr({})
        }
        setData({...tempData})
    }

    useEffect(()=> {
        if(props.user.resetPasswordAuth) {
            setLoader(false)
            if(props.user.resetMsg.success) {
                let error = {}
                error["msg"] = props.user.resetMsg.message
                error['sent'] = true
                setResetErr(error)
                setData({password: '',confirmPassword: ''})
            }
            else {
                let error = {}
                error["msg"] = props.user.resetMsg.message
                setResetErr(error)
            }
            props.beforeUser()
        }
    },[props.user.resetPasswordAuth])

    const validation = () => {
        let error = {}
        let isValid = true
        if (!data.password) {
            error["password"] = 'Field is required.'
            isValid = false
        }

        if(data.password && !validator.isStrongPassword(data.password, [{minLength: 8,minUppercase: 1,pointsForContainingNumber: 1}])) {
            error["password"] = 'Password should be at least 8 characters long with at least one lower case letter, upper case letter, special character and a digit.'
            isValid = false
        }

        if (!data.confirmPassword) {
            error["passwordConfirm"] = 'Field is required.'
            isValid = false
        }
        if (data.password !== data.confirmPassword) {
            if (isValid) {
                error["formErr"] = 'Both Password should be same.'
            }
            isValid = false
        }
        setErr(error)
        return isValid
    }

    const resetPwd = (e) => {
        e.preventDefault()
        if (validation()) {
            setLoader(true)
            props.resetPassword({ password: data.password, resetPasswordToken })
        }
    }

    useEffect(()=> {
        if(resetErr["sent"]) {
            setTimeout(() => {
                navigate("/sign-in")
            }, 1000);
        }
    },[resetErr])


    return (
        <div className="login">

            <Container>
                <Row className="justify-content-center align-items-center ">
                    <Col className="mb-4 mb-lg-0 shape-side index" lg={6}>
                        <div className="shape" >
                            <img className="img-fluid" src={Shape} alt="" />
                        </div>
                        <ul className="d-flex m-0 pl-4 list-unstyled align-items-baseline flex-wrap list-items" >
                            <li >
                                <div className="mb-4"><img src={Rating} alt="" /></div>
                                <span className=" text-white with-us">A big love with us</span>
                            </li>
                            <li>
                                <div className="mb-2">
                                    <img src={Regulate} alt="" />
                                </div>
                                <span className=" text-white">Official European Union license</span>
                            </li>
                            <li>
                                <div className="mb-2">
                                    {/* <img src={Regulate} alt="" /> */}
                                    <img className="img-fluid" src={Fifty} alt="" />
                                </div>
                                <span className=" text-white">over 50 exchanges automatically</span>
                            </li>
                        </ul>
                    </Col>
                    <Col className="mb-3 mb-lg-0 d-flex justify-content-center align-items-center index" lg={1}>
                        <div className="separator-div">
                        </div>
                    </Col>
                    <Col className="mb-3 mb-lg-0" lg={5}>
                        <Form onSubmit={resetPwd}>
                            <div className="login-form position-relative ">
                                <div className="top-heading">
                                    <h2>SET NEW PASSWORD</h2>
                                </div>
                                <div className=" password-input-account mb-4">
                                    <PasswordInput name="password" value={data.password} label="Password" changeData={changeData} errorMsg={err?.password} />
                                </div>
                                <div className=" password-input-account mb-5">
                                    <PasswordInput name="confirmPassword" value={data.confirmPassword} label="Confirm Password" changeData={changeData} errorMsg={err?.passwordConfirm} />
                                     {
                                    err["formErr"] && <p className="error">{err["formErr"]}</p>
                                }
                                {
                                    resetErr["msg"] && <p className={`error ms-1 ${resetErr["sent"] && "text-success"}`}>{resetErr["msg"]}</p>
                                }
                                </div>
                                <div className="confirmation">
                                    <div className="d-flex justify-content-end ">
                                        <button type="submit" className="btn-triage-div btn-triage  justify-content-center d-flex align-items-center"><span>Reset</span>{loader && <SubmitLoader />}</button>
                                    </div>
                                </div>
                               
                                <div style={{ bottom: "0" }} className="no-account">
                                    <span ><Link className="signup" to="/sign-in"> Return to Sign in page</Link> </span>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>

            </Container>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, { resetPassword, beforeUser })(ResetPassword)