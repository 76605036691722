import React,{ useState, useEffect } from "react";

import "./KYC.css";
import { Col, Row,  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Upload from "../../assets/images/upload.svg";
import { ENV } from "../../config/config";
const { allowedMediaTypes } = ENV;
function Passport(props) {
    const [ErrMsg, setErrMsg] = useState({
        DocumentFormatMsgFront: "",
        DocumentFormatMsgBack: "",
        
      });

      const SubmitImage=(e)=>
      {
        setErrMsg({
            DocumentFormatMsgBack: "",
            DocumentFormatMsgFront: "",
          });
        if (e.target.files[0]) {
            const allowedImgTypes = allowedMediaTypes.images;
            let fileExt = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      
            if (allowedImgTypes.includes(fileExt)) {
      
              let ImageData = URL.createObjectURL(e.target.files[0]);

             props.onChange(e.target.name,ImageData,e.target.files[0]);
            }
            else{
                if(e.target.name == 'personalDocumentPassportFront')
                    setErrMsg({
                        ...ErrMsg,
                        DocumentFormatMsgFront: "Please upload a document (.JPEG, .JPG, or .PNG)",
                      });
              
                else if(e.target.name == "personalDocumentPassportBack")
                setErrMsg({
                    ...ErrMsg,
                    DocumentFormatMsgBack: "Please upload a document (.JPEG, .JPG, or .PNG)",
                  });
                }
        }
       

   }
console.log(ErrMsg)
        

      
    return (
        <div className="passport">
            <div className="pt-4 d-flex justify-content-center align-items-center">
                <div>
                    <strong>Upload your passport pages
                    </strong>
                    <p>Please upload the corresponding document pages</p>
                    <div className="d-flex justify-content-center align-items-center">
              <Row>
                <Col xl={6} className="mb-3 mb-xl-0">
                  <span className="ps-2 mb-2">
                    Front<span className="text-danger"> *</span>
                  </span>

                  {props.data?.personalDocumentPassportFront ? (
                    <div className=" pt-3 pb-3 card-img-uploader position-relative">
                      <div className="close-section" onClick={() => props.ClosedPic("personalDocumentPassportFront")}>
                               
                        <FontAwesomeIcon className="text-white" icon={faXmark} />
                      </div>
                      <img
                        className="img-fluid"
                        alt=""
                        src={
                          props.data?.personalDocumentPassportFront
                        }
                      />
                    </div>
                  ) : (
                    <div className="card d-flex justify-content-center align-items-center">
                      <div className="mb-4 input-file">
                        <label>
                          {" "}
                          <img className="img-fluid" src={Upload} />
                          <input
                            type="file"
                            size="60"
                            accept=" .png, .jpeg, .jpg"
                            onChange={SubmitImage}
                            name={
                                `personalDocumentPassportFront`
                            }
                          />
                        </label>
                      </div>
                      <div className="p-2">
                        <span>Please upload a document (.JPEG, .JPG, or .PNG)</span>
                      </div>
                    </div>
                  )}
                  <span
                    className={
                      props.msg?.personalDocumentPassportFront ? `` : `d-none`
                    }
                  >
                    <label className="pl-1 text-danger error">
                      {props.msg?.personalDocumentPassportFront}
                    </label>
                  </span>
                  <span className={ErrMsg?.DocumentFormatMsgFront ? `` : `d-none`}>
                    <label className="pl-1 text-danger error">
                      {ErrMsg?.DocumentFormatMsgFront}
                    </label>
                  </span>
                </Col>
                <Col xl={6}>
                  <span className="ps-2 mb-2">
                    Back<span className="text-danger"> *</span>
                  </span>

                  {props.data?.personalDocumentPassportBack ? (
                    <div className="card-img-uploader pt-3 pb-3 position-relative">
                      <div className="close-section"  onClick={() => props.ClosedPic("personalDocumentPassportBack")} >
                        <FontAwesomeIcon className="text-white" icon={faXmark} />
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          props.data?.personalDocumentPassportBack            
                        }
                      />
                    </div>
                  ) : (
                    <div className="card d-flex justify-content-center align-items-center">
                      <div className="mb-4 input-file">
                        <label>
                          {" "}
                          <img className="img-fluid" src={Upload} />
                          <input
                            type="file"
                            size="60"
                            accept=".png,.jpeg,.jpg"
                            onChange={SubmitImage}
                            name={ `personalDocumentPassportBack`}
                          />
                        </label>
                      </div>
                      <div className="p-2">
                        <span>Please upload a document (.JPEG, .JPG, or .PNG)</span>
                      </div>
                    </div>
                  )}
                  <span
                    className={props.msg?.personalDocumentPassportBack ? `` : `d-none`}
                  >
                    <label className="pl-1 text-danger error">
                      {props.msg?.personalDocumentPassportBack}
                    </label>
                  </span>
                  <span className={ErrMsg?.DocumentFormatMsgBack ? `` : `d-none`}>
                    <label className="pl-1 text-danger error">
                      {ErrMsg?.DocumentFormatMsgBack}
                    </label>
                  </span>
                </Col>
              </Row>
            </div>
                </div>
            </div>
        </div>
    )
}
export default Passport